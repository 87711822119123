.agilarInputContainer {
    position: relative;
}
.agilarInput:disabled {
    cursor: default;
    background: #C0C1C1;
    opacity: 0.5;
}

.agilarInput {
    border: 0;
    border-bottom: 3px solid #dcdcdc;
    border-radius: 0;
    resize: none;
    display: block;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
}
.agilarInput::placeholder {
    opacity: 0;
}

.agilarInput:required + label::after,
.agilarInput.isRequired + label::after {
    content: "*";
    color: #ffc115;
}

.agilarInput:focus {
    box-shadow: none;
    outline: none;
    border-bottom: 3px solid #dcdcdc;
}

.agilarInput:focus + label,
.agilarInput:not(:placeholder-shown) + label {
    opacity: 0;
}

.agilarLabel {
    color: #838484;
    font-size: 14px;
    font-weight: 500;
    position: relative;
    left: 0.75rem;
    bottom: 30px;
    pointer-events: none;
}
