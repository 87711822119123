.services .header {
    color: #ffffff;
}

.service_box_main .services-data {
    display: none;
}

.services-link {
    font-weight: 400;
}

.service_box_main:hover .services-data {
    display: block !important;
}

.service_box_main:hover .services-link {
    font-weight: 700;
}
