.section-4 .container {
    padding-right: 200px !important;
}

.section-4 .history-container {
    position: relative;
    width: 100%;
    padding: 30px 30px 10px 30px;
    height: 650px;
    background: #FFC115;
}

.section-4 .history-container img {
    position: absolute;
    top: 35px;
    right: -20%;
    width: 50%;
    height: auto;
}

.carousel .slide {
    background: transparent !important;
    text-align: start !important;
}

.history-tab {
    height: 540px;
    width: calc(70% - 30px);
}

.history-tab .header {
    color: #ffffff;
    text-transform: uppercase;
}


.history-container .right-icon,
.history-container .left-icon {
    z-index: 1;
}

.history-container .left-icon {
    position: absolute;
    top: 50%;
    left: 5px;
}

.history-container .right-icon {
    position: absolute;
    top: 50%;
    right: calc(30% + 10px);
}

@media (max-width: 991px) {
    .history-tab .header {
        font-size: 1.2rem;
    }

    .section-4,
    .section-4 .container {
        padding-right: 0 !important;
        padding-left: 0 !important;
    }

    .section-4 .history-container {
        height: calc(100% - 140px);
    }

    .history-container .history-tab {
        width: calc(100% - 30px);
        height: calc(100%);
    }

    .history-container .right-icon {
        right: 5px;
    }
}