@import url(https://fonts.googleapis.com/css?family=Montserrat:400,500,600,700,800,900&display=swap);
html, body, #root {
    height: 100%;
}

body {
    font-family: 'Montserrat', sans-serif !important;
    margin: 0px;
    padding: 0px;
    font-weight: 500 !important;
}

.clickable:hover {
    cursor: pointer;
}

.btn.yellow-btn {
    font-size: 14px;
    font-weight: 700 ;
    color: #FFFFFF;
    background-color: #ffc116;
    width: 215px;
    height: 40px;
    border-radius: 0;
    padding: 10px;
    border-width: 2px;
}

.btn.yellow-btn:hover {
    color: #fff;
    background-color: #c0c1c1;
}

.btn.yellow-btn:focus {
    box-shadow: none !important;
}

.btn.yellow-btn.inverse {
    color: #ffc116 !important;
    background-color: #fff !important;
    border: 2px solid #ffc116 !important;
}

.btn.yellow-btn.inverse:hover {
    color: #fff !important;
    background-color: #ffc116 !important;
}

.validation_error {
    padding-left: 15px;
    color: #ff0000;
}

/*  Contact US page */
.contact-form {
    padding-top: 115px;
    padding-bottom: 40px !important;
}

.contact-form h6 {
    font-size: 14px;
    font-weight: 700;
    color: #ffc115;
    margin: 10px 0;
    letter-spacing: 0.5px;
}

.form-content {
    border: 0px !important;
    border-bottom: 2px solid #ffc200 !important;
    font-size: 14px !important;
    font-weight: 600 !important;
    color: #c0c1c1 !important;
    border-radius: 0px !important;
    padding-bottom: 15px !important;
    resize: none !important;
}

.form-content:focus {
    border-bottom-color: #ffc115;
    box-shadow: none !important;
}

.select-form {
    border: 0px !important;
    border-bottom: 2px solid #ffc200 !important;
    font-size: 14px !important;
    font-weight: 600 !important;
    color: #6c757d !important;
    border-radius: 0px !important;
    padding-bottom: 10px !important;
    background-color: #fff;
    -webkit-appearance: none;
    width: 100%;
    padding-left: 10px;
}

.select-form option {
    font-size: 14px !important;
    font-weight: 600 !important;
}

.select-form:focus {
    border-bottom-color: #ffc115;
    box-shadow: none !important;
}

.submit-btn {
    width: 100px !important;
    font-size: 16px !important;
    font-weight: 700 !important;
    text-transform: uppercase !important;
    padding: 8px !important;
    background-color: #c0c1c1 !important;
    color: #000 !important;
}

.submit-btn:hover {
    color: #000 !important;
}

.contact-input {
    border: 0px !important;
    border-bottom: 3px solid #dcdcdc !important;
    border-radius: 0px !important;
    resize: none;
    padding-bottom: 12px !important;
}

textarea.contact-input {
    max-height: 300px;
}

.contact-input:focus {
    box-shadow: none !important;
    border-bottom: 3px solid #dcdcdc !important;
}

.contact-placeholder {
    position: relative !important;
    padding: 0 30px !important;
}

.contact-placeholder .contact-input:focus + .contact-label {
    display: none;
}

.contact-label {
    position: absolute !important;
    pointer-events: none !important;
    top: 7px !important;
    bottom: 0 !important;
    font-size: 14px !important;
    font-weight: 500 !important;
    left: 60px !important;
    margin: auto !important;
    color: #838484;
}

.nearest_office_label {
    width: 100%;
    line-height: inherit;
}

.nearest_office_label.english {
    padding-left: 99px;
}

.nearest_office_label.spanish {
    padding-left: 145px;
}

.select-course {
    border-bottom: 3px solid #dcdcdc !important;
    font-weight: 500 !important;
    height: 38px !important
}

.contact-label span {
    color: #ffc115;
}

.contact-btn {
    width: 140px;
    font-size: 16px;
    font-weight: 500;
    text-transform: uppercase;
}

.contact-btn:hover {
    color: #000;
}

.register-label {
    left: 40px !important;
}

.form-input {
    margin-bottom: 24px;
}

/*  Cookies box CSS */
.cookieConsent {
    background-color: #4D4D4D !important;
    position: fixed !important;
    width: 100% !important;
    bottom: 0px !important;
    z-index: 100 !important;
    align-items: center !important;
}

.cookieConsent div {
    margin: 0px auto !important;
}

.cookies-box {
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
}

.cookieConsent h4 {
    color: #FFBE08;
    font-size: 13px;
    font-weight: 400;
    padding-top: 10px;
    margin-bottom: 0px;
}

.cookieConsent p {
    color: #FFF;
    font-size: 12px;
    font-weight: 400;
}

.cookieConsent .cookies-btn {
    text-transform: uppercase !important;
    font-size: 16px !important;
    font-weight: 400 !important;
    width: 100px !important;
    margin: 0px !important;
}

.cookieConsent .close-btn {
    padding: 5px 20px !important;
    color: #fff !important;
    font-size: 16px !important;
    background-color: transparent !important;
    margin: 15px 45px !important;
}

.cookieConsent .close-btn:focus {
    box-shadow: none;
}

/*terms and condition */
.terms-main {
    padding-top: 130px;
}

.terms-main h6 {
    color: #FFBE08;
    font-size: 22px;
    font-weight: 700;
}

.terms-main li {
    color: #FFBE08;
    font-size: 15px;
    font-weight: 700;
    padding-top: 10px;
    list-style-type: none;
}

.terms-main h5 {
    color: #FFBE08;
    font-size: 15px;
    font-weight: 700;
    padding-top: 10px;
    list-style-type: none;
}

.terms-main p {
    color: #000;
    font-size: 14px;
    font-weight: 400;
    padding-top: 10px;
}

.pb-100 {
    padding-bottom: 100px !important
}

.pb-250 {
    padding-bottom: 250px !important;
}

.footer-main li.agilar-copyright {
    font-size: 13px;
    font-weight: 400;
    color: #fff;
    text-decoration: none;
}

/*legal page*/
.legal-content p {
    margin-bottom: 0px;
}

.legal-content h5 {
    margin-bottom: 0px !important;
}

.terms-main h5 {
    color: #FFBE08;
    font-size: 15px;
    font-weight: 700;
    padding-top: 10px;
}

.terms-main a {
    color: #FFBE08;
    font-size: 14px;
    font-weight: 500;
    padding-top: 10px;
    text-decoration: none;
}

.terms-main a:hover {
    color: #FFBE08;
    text-decoration: none;
}

.legal-content .address-data {
    padding-top: 10px;
}

.legal-content .address-data p {
    padding-top: 0px;
}

/* Privacy page */
.table-privacy td {
    vertical-align: middle !important;
}

.table-privacy tr td:first-child {
    font-size: 14px;
    font-weight: 500;
    color: #000;
    width: 27%;
}

.table-privacy tr td:last-child {
    font-size: 14px;
    font-weight: 400;
    color: #000;
}

.table-privacy tr td ol {
    padding-left: 0px !important;
    margin-bottom: 0px;
}

.table-privacy tr td ol li {
    font-size: 14px;
    font-weight: 400;
    color: #000;
}

.terms-main .privacy-list {
    padding-left: 20px;
    margin-bottom: 0px;
}

.terms-main .privacy-list li {
    color: #000;
    font-size: 14px;
    font-weight: 400;
    list-style-type: disc;
}

/* cookies page */
.table-cookies tr td:first-child {
    width: 18% !important;
}

.highlighted-text {
    color: #FFBE08;
    font-weight: 600;
}

.privacy-list {
    list-style-type: disc;
    margin-bottom: 10px;
}

.error-page {
    padding-top: 150px;
}

.agilar-yellow {
    color: #FFC115
}


/*  Responsive CSS */
@media screen and (max-width: 576px) {
	.mobile-footer{
		display: none;
	}
	.mobile-input{
		margin-bottom: 25px;
	}
	.training-row{
		padding-bottom: 0px;
	}
	.training-data{
		padding-bottom: 50px;
	}
	.training-data img{
		margin: 20px auto;
	}
	.yellow-btn{
		width: 100% !important;
	}
	.services-box{
		min-height: 50px;
		margin-bottom: 20px !important;
	}
	.services-data{
		min-height: 150px;
	}
	.courses-box{
		padding-top: 0 !important;
	}
	.course-body{
		flex-direction: column !important;
	}
	.courses-box img{
		margin-top: 15px !important;
		margin-bottom: 15px !important;
	}
	.footer-main ul{
		padding-left: 25px !important;
	}
	.footer-one{
		position: static !important;
	}
	.footer-main-row{
		padding: 0px !important;
	}
	.footer-data{
		margin: 0 auto;
		justify-content: flex-start;
		padding-right: 0px !important;
	}
	.footer-data .footer-item{
		padding: 15px 0px;
	}
	.footer-one .social-box{
		padding: 0px !important;
	}
	.footer-one .social-box .social-content{
		padding: 0px !important;
	}
	.footer-one .social-box p{
		padding: 0px;
	}
	.description-box{
		border-right: 0px !important;
	}
	.description-head{
		flex-direction: column;
	}
	.description-head h4{
		font-size: 16px;
	}
	.description-head img{
		margin-left: 0px !important;
		margin-bottom: 20px;
	}
	.description-content{
		padding: 0px;
	}
	.training-description .registre-head{
		padding-top: 10px;
		padding-left: 0px !important;
	}
	.description-box .registration-content h5{
		padding-left: 0px !important;
		font-size: 15px;
	}
	.description-box .registration-content ul{
		padding: 0px !important;
	}
	.description-box .registration-content ul li{
		padding: 0 10px !important;
		display: block !important;
	}
	.registration-content .form-row .contact-placeholder{
		padding: 0px !important;
	}
	form .register-btn{
		margin-left: 0px !important;
	}
	.description-right{
		padding: 20px 0px;
	}
	.register-label {
		left: 10px !important;
	}
    .cookieConsent .cookies-btn{
    	margin: 15px !important;
    }
    .info-btn{
        height: 60px !important;
        width: 100%!important;
        line-height: 3 !important;
    }
	.more-btn{
        width: 100% !important;
        margin-left: 0px !important;
    }
    .info-head, .more-head{
    	padding: 0px !important;
    }
    .btn-training{
		width: 100% !important;
		padding: 8px 5px !important;
	}
	.gent-item{
		padding-right: 45px !important;
	}
	.footer-one .social-box p{
		padding: 0px !important;
	}
	.form-input{
	  margin-bottom: 0px;
	}
	.form-input input{
		margin-bottom: 10px;
	}
	.validation_error{
		padding-left: 22px;
	}
	.form_valid .validation_error{
		padding-left: 16px;
	}
	.mobile-placeholder{
		top: -9px !important;
	}
	.nearest_office_label.english{
		padding-left: 103px;
	}
	.nearest_office_label.spanish{
		padding-left: 150px;
	}
	.contact-label{
		left: 22px !important;
	}
	.contact-placeholder {
	    padding: 0 10px !important;
	}
	.contact_form .contact-placeholder {
		padding: 0 10px !important;
	}

	.section.clients .container{
		padding-bottom: 32px;
	}

}
@media screen and (max-width: 767px) {
	.slide .intro h1{
		font-size:35px !important;
		line-height: 40px !important;
	}
	.slide .intro .tags{
		font-size:15px !important;
		padding-top:25px !important;
	}
}
@media screen and (max-width: 991.98px){
	.register-label {
		left: 30px !important;
	}

	.contact-label{
		left: 22px !important;
	}
	.validation_error{
		padding-left: 12px;
	}
}
@media screen and (max-width: 1199px){
    .slider-data img{
        height: -webkit-fill-available
    }
    .footer-one h5{
    	font-size: 13px !important;
    }
    .footer-one p{
    	font-size: 11px !important;
    }
    .footer-one .social-box p{
    	font-size: 11px !important;
    }
    .footer-main a{
	    font-size: 11px !important;
	}
}
@media (min-width: 768px) and (max-width: 991.98px){
    .info-head{
        max-width: 100% !important;
        margin-left: 0% !important;
    }
    .info-btn{
    	width: 100% !important;
    }
    .courses-main .courses-container .courses-row{
		margin-left: 0% !important;
		max-width: 100% !important;
	}
	.courses-main .courses-container{
		max-width: 100% !important;
	}
	.btn-training{
		width: 135px !important;
		padding: 8px 5px !important;
	}
	.training-data p{
		min-height: 127px;
	}
	.training-data h4{
		min-height: 50px;
	}
	.btn-register{
		width: 100px;
		padding: 0px;
	}
	.footer-data{
		margin-left: 0 !important;
		max-width: 100% !important;
	}
	/*.footer-one .container{*/
	/*	max-width: 100% !important;*/
	/*	padding: 0px !important;*/
	/*}*/
	.social-box{
		padding: 0px !important;
	}
	.social-content{
		padding: 0px !important;
	}
	.footer-one .social-box p{
		padding: 0px !important;
	}
	.footer-main-row{
		padding: 0px !important;
	}

    .label-top{
    	top: -10px !important;
    }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
	.btn-register{
		width: 100px;
		padding: 0px;
	}
	.trainee-profile p{
		margin-right: 0px;
	}
	.btn-training{
		width: 135px !important;
		padding: 8px 5px !important;
	}
	.more-btn{
        margin-left: 0px !important;
        padding: 10px !important;
    }
    .info-btn{
        padding: 10px 0px !important;
    }
    .info-head{
        max-width: 93% !important;
        margin-left: 6% !important;
    }
}

.page-header {
    font-size: 42px;
    font-weight: 700;
}

.client-image {
    object-fit: contain;
}

.clients .client-image {
    width: 150px;
}

.clients-page .client-image {
    width: 80px;
}

.client-image-container {
    height: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px 0;
}

@media (max-width: 1280px) {
    .client-image-container {
        height: 120px;
    }

    .clients .client-image {
        width: 120px;
    }

    .clients-page .client-image {
        width: 60px;
    }

}

@media (max-width: 720px) {
    .page-header {
        font-size: 30px;
        font-weight: 700;
    }
}

.top-navigation{
	background: #fff;
	position:fixed;
	width:100%;
	top:0px;
	z-index: 100;
	border-bottom: 1px solid #ccc;
}
.top-navigation .navbar-brand img{
	width:150px;
}
.top-navigation .navbar-brand a{
	padding:0.3125rem 0 0.3125rem 0 !important;
}
.top-navigation .navbar{
	padding:1.3rem 1rem 1.3rem 1rem;
}
.top-navigation .navbar .nav-item a, .top-navigation .navbar button[type="button"].services_btn{
	margin-top:-1px;
}
.top-navigation .navbar  button[type="button"].services_btn{
	padding-top:0px !important;
	padding-bottom:6px !important;
}
.top-navigation .navbar a,
.top-navigation .navbar button[type="button"].services_btn{
	text-transform: uppercase;
	font-size:13px;
	font-weight: 500;
	position: relative;
	color:#000;
	letter-spacing: 0.5px;
	border-top:solid 2px transparent;
	text-decoration: none;
	padding:8px;
	padding-left:7px;
	padding-right:7px;
	border-bottom: 2px solid transparent;
}
.top-navigation .navbar a.active,.top-navigation .navbar button[type="button"].active.services_btn{
	color:#838484 !important;
	border-bottom:solid 2px #ffc115 !important;
	border-radius:0px !important;
}
.top-navigation .navbar a:hover, .top-navigation .navbar button[type="button"].services_btn:hover{
   border-bottom:solid 2px #ffc115 !important;
   border-radius:0px !important;
}
.top-navigation .navbar .dropdown-menu a:hover{
   border-bottom: 0px !important;
   border-radius: 0px !important;
   color:#ffc115 !important;
}
.top-navigation .navbar .navbar-brand a:hover{
   border-bottom: 0px !important;
   border-radius: 0px !important;
}
.top-navigation .navbar .nav-item a:hover::after{
	width:100%;
}
.top-navigation .navbar .nav-item .dropdown-toggle::after{
	background: transparent;
}
.top-navigation .navbar .nav-item a:hover{
	color:#000;
}

.top-navigation .navbar ul li a::after {
    content: '';
    display: block;
    width: 0;
    height: 2px;
    background: #ffc115;
    transition: width .3s;
    position: absolute;
    bottom:-3px;
    left:0;
    right:0;
    margin:0 auto;
}

.top-navigation .navbar ul li a::after{
	background: none;
}
#navbarSupportedContent .dropdown:hover .dropdown-menu, #navbarSupportedContent .btn-group:hover .dropdown-menu{
        display: flex;
 }

.top-navigation .navbar .dropdown-item.active, .dropdown-item:active{
	background: none;
}
.top-navigation .navbar-expand-lg .nav-item{
	margin-right:5px;
	border-bottom: 2px solid transparent;
}
.top-navigation .navbar-expand-lg .nav-item:hover{
	border-bottom:solid 2px transparent;
}
.navbar-toggler-icon img{
	width:25px;
}
.top-navigation .lang-dd .dropdown-menu:hover{
	display: block;
}
.top-navigation .lang-dd{
	margin:0 5px;
}
.lang-dd:hover .dropdown-menu{
        display: block;
 }
 .lang-dd .dropdown-toggle:after{
 	border:none;
 }
.lang-dd .dropdown-toggle{
	background: url(/images/down-arrow.svg) no-repeat;
 	color:#000;
 	font-size:13px;
 	text-decoration: none;
 	padding-left: 20px;
 	padding-right: 8px;
  	background-position: left;
  	background-size: 15px;
	margin-top:-5px;
	font-weight: 500;
 }
.lang-dd .dropdown-menu{
  	min-width:3rem;
  	font-size:13px;
  	border-radius:0px;
 }
.lang-dd .dropdown-item{
	padding:0 0.8rem !important;
	font-size:13px !important;
	font-weight:500;
	text-transform: capitalize !important;
}
.top-navigation .lang-dd .dropdown-menu .dropdown-item{
	width:100%;
	clear:both;
	border-bottom: 0px;
}
.top-navigation .country-dd img{
	width:20px;
}
.country-dd .dropdown-item:hover{
	background: none;
}
.navbar .country-dd .dropdown-menu a{
  border-bottom: 0px !important;
}
.country-dd .dropdown-item{
	margin-bottom:5px;
	padding:0px !important;
}
.country-dd .dropdown-menu{
	min-width:3rem;
	padding:10px;
	text-align: center;
	left:5px;
	border: 0px !important;
	top: 80% !important;
}
.dropdown-toggle::after{
	border: none !important;
}
.dropdown-data{
	margin-right: 5px;
}
.dd-border{
    min-width: 2rem !important;
    padding: 4px 10px 2px 10px !important;
    top: 30px !important;
    background-color: #fff !important;
}
.dd-border a{
	padding: 0px !important;
}
.dd-border a{
	border-bottom: 2px solid transparent !important;
}
.btn-drop{
	font-size: 15px !important;
	color: #000 !important;
	font-weight: 500 !important;
	background-color: transparent !important;
	border-color: transparent !important;
	text-transform: uppercase;
	padding: 8px 7px !important;
}
.btn-drop:focus{
	box-shadow: none !important;
	outline: none !important;
}
.btn-drop:hover {
    color: #000 !important;
    background-color: transparent !important;
    border-color: transparent !important;
}
.btn-lang-dd{
	background-color: transparent !important;
	border-color: transparent !important;
	color: #000 !important;
}
.btn-lang-dd:focus{
	box-shadow: none !important;
}
.navbar-light .navbar-toggler{
	border-color: transparent !important;
}
.country-item.dropdown-menu.show{
  display: flex !important;
  flex-direction: column !important;
}
.country-lang{
    display: none;
}
.country-language{
    display: flex;
    flex-direction: row;
}
.dropdown:hover .dropdown-menu {
    display: inline-block;
}
.top-navigation .navbar .navbar-brand a{
	border-top: 0px;
	border-bottom: 0px;
}

.services-dropdown {
	position: relative;
}

.services-dropdown:hover .training-link {
	display: block;
}

.training-link {
	display: none;
	position: absolute;
	color:#454647;
	font-size:13px !important;
	padding: 17px 0;
	width: 100%;
	margin: 0 auto;
	text-align: center;
}

.training-link:hover {
	color: #ffc115;
	cursor: pointer;
}

/*navbar responsiveness*/
@media screen and (max-width: 576px) {
	.lang-dd .dropdown-toggle{
		padding-left: 18px;
    	padding-right: 2px;
    	margin-top: 5px;
	}
	.top-navigation .navbar a, .top-navigation .navbar button[type="button"].services_btn{
		border-bottom: 0px !important;
		border-top: 0px !important;
	}
	.top-navigation .navbar button[type="button"].services_btn{
		padding-bottom: 0px !important;
	}
	.navbar-toggler{
		padding: 0.25rem 0.50rem !important;
	}
}
@media screen and (max-width: 767px) {
	.top-navigation .navbar ul li.active a {
		border: none;
	}
	.top-navigation .navbar ul li a::after{
		background: none;
	}
	.top-navigation .navbar .dropdown-menu a{
		text-align: left;
	}
	.top-navigation .navbar .dropdown-menu{
	}
}
@media screen and (max-width: 991.98px){
	.nav .dropdown-menu{
		position: static;
		float: none;
		padding-top: 0px !important;
	}
	.top-navigation .navbar .country-item a{
    	text-align: center !important;
  	}
	.country-lang{
		display: flex;
		flex-direction: row;
		position: absolute;
	    top: 30px;
	    right: 65px;
	}
	.country-language{
		display: none;
	}
	.container {
     	max-width: 100%;
	}
	.top-navigation .navbar ul li.active a{
		color:#838484;
		border-bottom:none;
	}
	.top-navigation .navbar-expand-lg .navbar-nav .nav-link:hover{
		border-bottom: none;
	}
	.navbar-nav{
		margin-top: 50px;
	}
	.lang-dd .dropdown-toggle{
		padding-left: 18px;
    	padding-right: 2px;
    	margin-top: 5px;
	}
	.top-navigation .navbar-expand-lg .nav-link:hover{
		border-bottom: none;
	}
	.nav{
		flex-direction: column;
		margin-top: 50px;
	}
	.top-navigation .navbar-expand-lg .nav-link.active{
		border-bottom: none;
	}
	.top-navigation .lang-dd{
		margin:0px;
	}
	.top-navigation .navbar a.active, .top-navigation .navbar button[type="button"].active.services_btn{
	    color: #000 !important;
	    border-bottom: 0px !important;
	}
	.top-navigation .navbar a:hover, .top-navigation .navbar button[type="button"].services_btn:hover{
		border-bottom: 0px !important;
	}
	#navbarSupportedContent .dropdown:hover .dropdown-menu, #navbarSupportedContent .btn-group:hover .dropdown-menu{
		display: none;
	}
	.services_btn{
		padding: 0px 0px 0px 7px !important;
	}
}
@media (min-width: 768px) and (max-width: 991.98px) {
	.navbar{
		justify-content: space-between !important;
	}
}
@media (min-width: 992px) and (max-width: 1199.98px) {
	.top-navigation .navbar a{
		margin: -1px;
	}
}
nav .navbar-collapse .dropdown{
	margin-right: 5px;
}

/*!
 * fullPage 3.0.7
 * https://github.com/alvarotrigo/fullPage.js
 *
 * @license GPLv3 for open source use only
 * or Fullpage Commercial License for commercial use
 * http://alvarotrigo.com/fullPage/pricing/
 *
 * Copyright (C) 2018 http://alvarotrigo.com/fullPage - A project by Alvaro Trigo
 */
html.fp-enabled,
.fp-enabled body {
    margin: 0;
    padding: 0;
    overflow:hidden;

    /*Avoid flicker on slides transitions for mobile phones #336 */
    -webkit-tap-highlight-color: rgba(0,0,0,0);
}

.fp-section {
    position: relative; /* Safari<=5 Android<=3 */ /* <=28 */
    box-sizing: border-box;
}
.fp-slide {
    float: left;
}
.fp-slide, .fp-slidesContainer {
    height: 100%;
    display: block;
}
.fp-slides {
    z-index:1;
    height: 100%;
    overflow: hidden;
    position: relative; /* Safari<=6 Android<=4.3 */
    transition: all 0.3s ease-out;
}
.fp-section.fp-table, .fp-slide.fp-table {
    display: table;
    table-layout:fixed;
    width: 100%;
}
.fp-tableCell {
    display: table-cell;
    vertical-align: middle;
    width: 100%;
    height: 100%;
}
.fp-slidesContainer {
    float: left;
    position: relative;
}
.fp-controlArrow {
    -webkit-user-select: none; /* webkit (safari, chrome) browsers */
    -moz-user-select: none; /* mozilla browsers */
    -khtml-user-select: none; /* webkit (konqueror) browsers */
    -ms-user-select: none; /* IE10+ */
    position: absolute;
    z-index: 4;
    top: 50%;
    cursor: pointer;
    width: 0;
    height: 0;
    border-style: solid;
    margin-top: -38px;
    transform: translate3d(0,0,0);
}
.fp-controlArrow.fp-prev {
    left: 15px;
    width: 0;
    border-width: 38.5px 34px 38.5px 0;
    border-color: transparent #fff transparent transparent;
    display: none;
}
.fp-controlArrow.fp-next {
    right: 15px;
    border-width: 38.5px 0 38.5px 34px;
    border-color: transparent transparent transparent #fff;
    display: none;
}
.fp-scrollable {
    overflow: hidden;
    position: relative;
}
.fp-scroller{
    overflow: hidden;
}
.iScrollIndicator{
    border: 0 !important;
}
.fp-notransition {
    transition: none !important;
}
#fp-nav {
    position: fixed;
    z-index: 100;
    margin-top: -32px;
    top: 50%;
    opacity: 1;
    -webkit-transform: translate3d(0,0,0);
}
#fp-nav.fp-right {
    right: 17px;
}
#fp-nav.fp-left {
    left: 17px;
}
.fp-slidesNav{
    position: absolute;
    z-index: 4;
    opacity: 1;
    transform: translate3d(0,0,0);
    left: 0 !important;
    right: 0;
    margin: 0 auto !important;
}
.fp-slidesNav.fp-bottom {
    bottom: 17px;
}
.fp-slidesNav.fp-top {
    top: 17px;
}
#fp-nav ul,
.fp-slidesNav ul {
  margin: 0;
  padding: 0;
}
#fp-nav ul li,
.fp-slidesNav ul li {
    display: block;
    width: 14px;
    height: 13px;
    margin: 7px;
    position:relative;
}
.fp-slidesNav ul li {
    display: inline-block;
}
#fp-nav ul li a,
.fp-slidesNav ul li a {
    display: block;
    position: relative;
    z-index: 1;
    width: 100%;
    height: 100%;
    cursor: pointer;
    text-decoration: none;
}
#fp-nav ul li a.active span,
.fp-slidesNav ul li a.active span,
#fp-nav ul li:hover a.active span,
.fp-slidesNav ul li:hover a.active span{
    background: #333;
    height: 12px;
    width: 12px;
    margin: -6px 0 0 -6px;
    border-radius: 100%;
 }
#fp-nav ul li a span,
.fp-slidesNav ul li a span {
    border-radius: 50%;
    position: absolute;
    z-index: 1;
    height: 12px;
    width: 12px;
    border: 0;
    border:solid 1px #333;
    left: 50%;
    top: 50%;
    margin: -6px 0 0 -6px;
    transition: all 0.1s ease-in-out;
}
#fp-nav ul li:hover a span,
.fp-slidesNav ul li:hover a span{
    background: #333;
    width: 12px;
    height: 12px;
    margin: -6px 0px 0px -6px;
}
#fp-nav ul li .fp-tooltip {
    position: absolute;
    top: -2px;
    color: #fff;
    font-size: 14px;
    white-space: nowrap;
    max-width: 220px;
    overflow: hidden;
    display: block;
    opacity: 0;
    width: 0;
    cursor: pointer;
}
#fp-nav ul li:hover .fp-tooltip,
#fp-nav.fp-show-active a.active + .fp-tooltip {
    transition: opacity 0.2s ease-in;
    width: auto;
    opacity: 1;
}
#fp-nav ul li .fp-tooltip.fp-right {
    right: 20px;
    color: #000;
    font-weight: 400;
}
#fp-nav ul li .fp-tooltip.fp-left {
    left: 20px;
}
.fp-auto-height.fp-section,
.fp-auto-height .fp-slide,
.fp-auto-height .fp-tableCell{
    height: auto !important;
}

.fp-responsive .fp-auto-height-responsive.fp-section,
.fp-responsive .fp-auto-height-responsive .fp-slide,
.fp-responsive .fp-auto-height-responsive .fp-tableCell {
    height: auto !important;
}

/*Only display content to screen readers*/
.fp-sr-only{
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border: 0;
}
    /* Style for our header texts
    * --------------------------------------- */
    h1{
        font-size: 5em;
        color: #fff;
        margin:0;
    }
    .intro p{
        color: #fff;
    }

    /* Centered texts in each section
    * --------------------------------------- */

    /* Overwriting styles for control arrows for slides
    * --------------------------------------- */
    .controlArrow.prev {
        left: 50px;
    }
    .controlArrow.next{
        right: 50px;
    }


    /* Bottom menu
    * --------------------------------------- */
    #infoMenu li a {
        color: #fff;
    }

#section1{
	background-color: #ffc116 !important;
}
#section1 .fp-tableCell,
#section2 .fp-tableCell,
#section3 .fp-tableCell{
	vertical-align: top;
}
.slider-data .intro{
    top: 225px;
    right: 0%;
    left: 0%;
    text-align: left;
}
.slider-data.intro-agilar{
    /* background-image: url("/images/home_agilar.png"); */
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    height: 100vh;
}
.slider-data.intro-scrum{
    /* background-image: url("/images/home_scrum.png"); */
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    height: 100vh;
}
.slider-data.intro-lean{
    /* background-image: url("/images/home_lean.png"); */
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    height: 100vh;
}
.slider-data.intro-lean{
    /* background-image: url("/images/home_lean.png"); */
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    height: 100vh;
}
.slider-data.argentina{
	background: linear-gradient(rgba(255,255,255,0.5), rgba(255,255,255,0.5)), url("/images/Home_Argentina.png");
	background-size: cover;
	background-repeat: no-repeat;
	width: 100%;
	height: 100vh;
}
.slider-data.belgium{
	background: linear-gradient(rgba(255,255,255,0.5), rgba(255,255,255,0.5)), url("/images/Home_Belgium.png");
	background-size: cover;
	background-repeat: no-repeat;
	width: 100%;
	height: 100vh;
}
.slider-data.spain{
	background: linear-gradient(rgba(255,255,255,0.5), rgba(255,255,255,0.5)), url("/images/Home_Spain.png");
	background-size: cover;
	background-repeat: no-repeat;
	width: 100%;
	height: 100vh;
}

.slider-data .intro h1{
    color:#000;
    font-weight:700;
    font-size: 65px;
    line-height: 60px;
    text-transform: uppercase;
}
.slider-data .intro span{
    font-size:30px;
    text-transform: uppercase;
    color: #000;
}
.slider-data .intro span span{
    font-weight:700;
    color:#000;
    text-transform: uppercase;
}
.slider-data .intro .tags{
    font-weight: 500;
    font-size: 22px;
    padding-top: 50px;
    color: #000;
    text-transform: uppercase;
}
.carousel-indicators{
    bottom: 30px !important;
	display: none !important;
}
.carousel-indicators li{
    width: 10px !important;
    height: 10px !important;
    border-radius: 50% !important;
    border: 1px solid #000 !important;
    background-color: transparent !important;
    margin-left: 8px !important;
    margin-right: 8px !important;
    opacity: 1 !important;
}
.carousel-indicators .active{
    opacity: 1 !important;
    background-color: #000 !important;
    border: 1px solid #000;
}
.fp-slidesNav{
	text-align: center;
}
/* our services */
.content {
    padding-top: 180px;
}

.section.services .header .border-head{
    border-top: 10px solid #fff;
    padding-top: 0px;
}
.services-row{
    padding-top: 80px;
}
.services-box{
    min-height: 0px;
}
.services-height{
    height: 30px;
}
.services-box a{
    font-size: 18px;
    color: #fff !important;
    font-weight: 400;
    text-decoration: none !important;
}
.services-box a .data-head{
    border-top: 4px solid #000;
    padding-top: 4px;
}
.services-data{
    padding-top: 10px;
    min-height: 200px;
}
.services-data p{
    font-size: 0.85rem;
    font-weight: 400;
    color: #464647;
}
.services-data p:hover{
    font-weight: 400;
    color: #fff;
}
.services-data h6{
    font-size: 0.85rem;
    font-weight: 400;
    color: #464647;
    margin-bottom: 0px;
    padding: 4px 0px 10px 28px;
}
.services-data .services-csm{
    background: url('/images/services1.png');
    background-repeat: no-repeat;
    background-size: 25px;
}
.services-data .services-csm:hover{
    background: url('/images/services4.png');
    background-repeat: no-repeat;
    background-size: 25px;
}
.services-data .services-cspo{
    background: url('/images/services2.png');
    background-repeat: no-repeat;
    background-size: 25px;
}
.services-data .services-cspo:hover{
    background: url('/images/services3.png');
    background-repeat: no-repeat;
    background-size: 25px;
}
.services-data h6:hover{
    font-weight: 400;
    color: #fff;
}
/* Slider dots */
#fp-nav ul li a span, .fp-slidesNav ul li a span{
    width: 12px !important;
    height: 12px !important;
    background-color: transparent !important;
    border: 1px solid #333 !important;
    margin: -6px 0 0 -6px !important;
}
#fp-nav ul li a.active span, .fp-slidesNav ul li a.active span,
#fp-nav ul li a span:hover, .fp-slidesNav ul li a span:hover{
    width: 12px !important;
    height: 12px !important;
    background-color: #333 !important;
    border: 1px solid #333 !important;
    margin: -6px 0 0 -6px !important;
}
#fp-nav ul li .fp-tooltip.fp-right{
    font-family: 'Montserrat', sans-serif !important;
}
#fp-nav ul li .fp-tooltip{
   top: -4px !important;
}
/* footer */
.footer-one{
    background: #666666;
    width:100%;
    z-index: 100;
}
.footer-content{
    padding-top: 20px;
}
.footer-one h5{
    margin-bottom: 0px;
    font-size: 14px;
    font-weight: 700;
    color: #fff;
    text-transform: uppercase;
}
.footer-one p,
.footer-one a{
    margin-bottom: 0px;
    font-size: 12px;
    font-weight: 400;
    color: #fff;
}

.footer-one a:hover {
    color: #fff;
}

.footer-one .social-box p{
    font-size: 12px;
    color: #ffbe08;
    font-weight: 500;
    padding: 0px 35px;
}
.social-link{
    display: flex;
    align-items: center;
    padding: 20px 0px;
}
.social-link .social-icons{
    padding: 0px 10px;
    width: 55px;
}
.footer-main{
    background-color: #4d4d4d;
    padding-top: 20px;
}
.footer-main a{
    font-size: 12px;
    font-weight: 400;
    color: #fff;
    text-decoration: none;
}
.footer-main a:hover{
    color: #ffbe08;
    text-decoration: none;
}
.footer-main ul li{
    margin-right: 30px !important;
}
.footer-data{
    /*justify-content: space-between;*/
}

.argentina-qr {
    margin-top: 20px;
    height: 80px;
}

.footer-item-content {
    min-width: 185px;
}

.section .header {
    font-size: 56px;
    font-weight: 700;
}

.section .text-row{
    padding-top: 30px;
}
.section .text-row{
    font-size: 0.85rem;
    font-weight: 400;
    color: #000;
}

.yellow-btn.home-cta {
    color: #000000;
    width: 400px;
    font-weight: 500 !important;
    margin-top: 40px;
}

@media (max-width: 1280px) {
    .section .header {
        font-size: 40px;
    }
}

@media (max-width: 720px) {
    .section .header {
        font-size: 30px;
    }
}

@media (max-width: 576px) {
    .footer-item > .footer-item-content {
        width: 185px !important;
        margin: 0 auto;
    }

    .yellow-btn.home-cta {
        font-size: 11px !important;
    }
}

.services .header {
    color: #ffffff;
}

.service_box_main .services-data {
    display: none;
}

.services-link {
    font-weight: 400;
}

.service_box_main:hover .services-data {
    display: block !important;
}

.service_box_main:hover .services-link {
    font-weight: 700;
}

.section.about .fp-tableCell {
    vertical-align: top;
}

.section.about {
    background: linear-gradient(rgba(255, 193, 21, 0.25), rgba(255, 193, 21, 0.25)),
        url('/images/about-us-background.png') 50% 5%;
    background-size: cover;
}

.about .text-row p {
    font-size: .97rem;
    font-weight: 400;
    color: #000;
}

.about .btn.yellow-btn.about-btn {
    margin-top: 32px;
    width: 275px;
}

/*.about .btn.yellow-btn.about-btn:hover {*/
/*    background: #FFFFFF !important;*/
/*    color: #FFC115 !important;*/
/*    border: 2px solid #FFC115;*/
/*}*/

.section.about .header .border-head,
.section.clients .header .border-head {
    border-top: 10px solid #ffc115;
    padding-top: 0;
}

.overlay {
    background: rgba(255, 193, 21, 0.7);
    transition: .5s ease;
    opacity: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: .8em;
    padding: 10px;
    height: 100%;
    width: 100%;
    cursor: default;
    text-align: center;
}
.overlay > p:hover {
    cursor: default;
}

.client-image-container:hover .overlay {
    opacity: 1;
}

.client-image-container:hover .client-image {
    /*opacity: 0.3;*/
}


.FloatingLabelInput_agilarInputContainer__2tZ0H {
    position: relative;
}
.FloatingLabelInput_agilarInput__iSQD6:disabled {
    cursor: default;
    background: #C0C1C1;
    opacity: 0.5;
}

.FloatingLabelInput_agilarInput__iSQD6 {
    border: 0;
    border-bottom: 3px solid #dcdcdc;
    border-radius: 0;
    resize: none;
    display: block;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
}
.FloatingLabelInput_agilarInput__iSQD6::-webkit-input-placeholder {
    opacity: 0;
}
.FloatingLabelInput_agilarInput__iSQD6:-ms-input-placeholder {
    opacity: 0;
}
.FloatingLabelInput_agilarInput__iSQD6::-ms-input-placeholder {
    opacity: 0;
}
.FloatingLabelInput_agilarInput__iSQD6::placeholder {
    opacity: 0;
}

.FloatingLabelInput_agilarInput__iSQD6:required + label::after,
.FloatingLabelInput_agilarInput__iSQD6.FloatingLabelInput_isRequired__3RqYv + label::after {
    content: "*";
    color: #ffc115;
}

.FloatingLabelInput_agilarInput__iSQD6:focus {
    box-shadow: none;
    outline: none;
    border-bottom: 3px solid #dcdcdc;
}

.FloatingLabelInput_agilarInput__iSQD6:not(:-ms-input-placeholder) + label {
    opacity: 0;
}

.FloatingLabelInput_agilarInput__iSQD6:focus + label,
.FloatingLabelInput_agilarInput__iSQD6:not(:placeholder-shown) + label {
    opacity: 0;
}

.FloatingLabelInput_agilarLabel__2e2Sm {
    color: #838484;
    font-size: 14px;
    font-weight: 500;
    position: relative;
    left: 0.75rem;
    bottom: 30px;
    pointer-events: none;
}

.InputWrapper_validationError__1UJL0 {
    font-size: 80%;
    font-weight: 400;
    padding-left: 0.75rem;
    color: #ff0000;
    position: relative;
    bottom: 31px;
}

.InputWrapper_inputContainer__1J7gs {
    height: 60px;
}


/* training page */
.training-row{
	padding-bottom: 80px;
}
.training-main{
	padding-top: 150px;
}
.training-data{
	display: flex;
	flex-direction: column;
}
.training-data .image-container {
	height: 70px;
	width: 70px;
	margin-bottom: 20px;
	display: flex;
	align-items: center;
}
.training-data .image-container img{
	display: block;
	max-height: 100%;
	max-width: 100%;
}
.training-data h4{
	font-size: 16px;
	font-weight: 700;
	min-height:40px;
	margin-bottom: 0px;
	text-transform: uppercase;
}
.training-data h4 .border-content,
.registration-success .border-content {
	border-top: 8px solid #ffc116;
	padding-top: 8px;
}
.training-data p{
	font-size: 0.86rem;
	font-weight: 400;
	padding-bottom: 7px;
	margin-bottom: 0px;
	height: 100px;
}
.training-data a{
	font-size: 15px;
	color: #fec117;
	font-weight: 700;
	padding-bottom: 15px;
	text-decoration: none;
	width: 215px;
}
.training-data a:hover{
	color: #c0c1c1;
	text-decoration: none;
}
.training-data{
	min-height:300px;
	position: relative;
	margin-bottom:50px;
}
.training-data .yellow-btn{
	position:absolute;
	bottom: 0;
}
.training-description .category_description{
	border: none;
}

.no-training form {
	margin-top: 32px;
}

.NoTrainings_noTraining__3w8nQ form {
    margin-top: 24px;
    margin-bottom: 48px;
}

.NoTrainings_noTraining__3w8nQ {
    margin-top: 32px;
}

.NoTrainings_noTraining__3w8nQ form.NoTrainings_submitted__3-KhK input {
    border-bottom-color: #00B388 !important;
}

.NoTrainings_noTraining__3w8nQ h4 {
    font-size: 16px;
    font-weight: normal;
    min-height:40px;
    margin-bottom: 24px;
}

.NoTrainings_borderContent__8adNr {
    border-top: 8px solid #ffc116;
    padding-top: 8px;
}


/* upcoming courses */
.courses-main{
	padding-top: 150px;
}
.courses-main h3{
	font-size: 18px;
	font-weight: 600;
	color: #838484;
}
.courses-row{
	padding-bottom: 40px;
}
.courses-box{
	padding-top: 40px;
	padding-bottom: 16px;
	border-bottom: 1px solid #838484;
}
.courses-box img{
	width: 100px;
}
.courses-box .content-head,
.courses-box .content-head a{
	font-size: 19px;
	color: #000;
	font-weight: 600;
	padding-bottom: 15px;
	margin-bottom: 0px;
}
.courses-box:hover .content-head{
	color: #838484;
	text-decoration: none;
}
.courses-box h6{
	font-size: 14px;
	color: #838484;
	font-weight: 400;
	margin-bottom: 0px;
}
.course-body{
	font-size: 14px;
	display: flex;
	flex-direction: row;
	margin-top: 10px;
}
.course-body .course-data{
	padding: 0 5px;
}

.full-warning {
	position: absolute;
	top: -20px;
	width: 100%;
	padding: 0 5px;
	text-align: center;
	color: #FF0000;
	font-size: 13px;
	font-weight: 400;
}
.course-data h6,
.course-data .course-price {
	font-size: 14px;
	font-weight: 400;
	color: #000;
	padding-top: 8px;
}

.course-data .course-price span {
	position: absolute;
	width: 200px;
}

.course-data .course-description{
	font-size: 14px;
	font-weight: 700;
	color: #000;
}
.course-data span:hover{
	cursor: default;
}
.btn-register{
	width: 120px !important;
	font-size: 12px !important;
	height: 35px !important;
	padding: 0px !important;
}

/* Training descritopn */

.training-description{
	padding-top: 120px;
}
.training-description a{
	color: #fec117;
	font-size: 16px;
	text-decoration: none;
}
.training-description a:hover{
	text-decoration: none;
	color: #fec117;
}
.description-head{
	display: flex;
	align-items: center;
	justify-content: space-between;
}
.description-box{
	border-right: 1px solid #ffc116;
}
.description-head h4{
	font-size: 17px;
	font-weight: 700;
	color: #838484;
}
.description-head img{
	width: 90px;
	margin-left: 60px;
}
.description-content{
	padding-right: 30px;
}
.description-content h5{
	font-size: 15px;
	color: #000;
	font-weight: 500;
	margin-bottom: 20px;
}
.description-content h6{
	font-size: 15px;
	color: #000;
	font-weight: 700;
	text-align: justify;
	margin-bottom: 25px;
}
.description-content p{
	font-size: 15px;
	color: #000;
	font-weight: 500;
	margin-bottom: 20px;
}
.description-content ul{
	padding-left: 0px;
}
.description-content ul li{
	list-style-type: none;
	font-size: 15px;
	color: #000;
	font-weight: 500;
}
.description-right h5{
	font-size: 15px;
	color: #000;
	font-weight: 700;
	text-transform: uppercase;
	margin-bottom: 0px;
}
.description-right p{
	font-size: 15px;
	color: #000;
	font-weight: 500;
}
.trainee-profile{
	display: flex;
	align-items: center;
	margin-bottom: 15px;
}
.trainee-profile p{
	font-size: 16px;
	font-weight: 500;
	color: #000;
	margin: 10px;
}
.trainee-profile img:not(.partner-image){
	width: 40px;
	border-radius: 50%;
}
.more-info{
	padding-top: 80px;
}
.more-info h6{
	font-size: 16px;
	font-weight: 500;
	color: #000;
	margin-bottom: 0px;
}
.more-info a{
	font-size: 16px;
	font-weight: 700;
	color: #fec117;
}
.btn-training{
	font-weight: 500 !important;
	width: 100%;
	font-size: 15px !important;
	padding: 8px !important;
}

/* Training Registration */

.training-description .registre-head{
	font-size: 1.12rem;
}
.description-box .registration-content ul li{
	font-size: 16px;
	font-weight: 600;
	color: #000;
	text-transform: uppercase;
}
.description-box .registration-content h5{
	font-size: 16px;
	color: #fec117;
	text-transform: uppercase;
	font-weight: bold;
}
.register-btn{
	width: 150px !important;
	font-weight: 600 !important;
	text-transform: uppercase !important;
}
.more-btn{
	font-weight: 600 !important;
	text-transform: uppercase !important;
	margin-bottom: 24px;
	margin-left: 24px;
	width: auto !important;
}
.info-btn{
	font-weight: 600 !important;
	text-transform: uppercase !important;
	font-size: 14px !important;
	color: #fff !important;
	background-color: #ffc116 !important;
	border-radius: 0px !important;
	padding: 10px !important;
}
.trainee-head{
	display: flex;
    align-items: center;
    justify-content: space-between;
}
.trainee-head h6{
	font-size: 15px;
	font-weight: 500;
	color: #fec117;
	text-transform: uppercase;
	margin-bottom: 0px;
}
.flash_msg{
	color: #fff;
	padding: 8px;
	margin: 0px 15px;
	margin-bottom: 15px;
}
/* courses contact us form */
.contact-form{
	padding-top: 105px;
}
.contact-head h6{
	font-size: 16px;
	font-weight: 700;
	color: #838484;
	/*margin-left: 35px;*/
	margin-bottom: 25px;
	text-transform: uppercase;
}
.contact-head p{
	font-size: 16px;
	font-weight: 500;
	color: #000;
	/*margin-left: 35px;*/
	margin-bottom: 0px;
}
.contact-input{
	border: 0px;
	border-bottom: 3px solid #dcdcdc;
	border-radius: 0px;
	resize: none;
	padding-bottom: 12px;
}
.contact-input:focus{
	box-shadow: none;
	border-bottom: 3px solid #dcdcdc;
}
.contact_form .contact-placeholder {
  padding: 0 48px !important;
}
.contact-placeholder .contact-input:focus + .contact-label {
  display: none;
}
.contact-label {
  position: absolute;
  pointer-events: none;
  top: 0;
  bottom: 0;
  font-size: 14px;
  font-weight: 500;
  left: 60px;
  margin: auto;
  color: #838484;
}
.contact-label span {
  color: #ffc115;
}
.contact-btn{
	width: 140px;
	font-size: 16px;
	font-weight: 500;
	text-transform: uppercase;
	/*margin-left: 48px !important;*/
}
.contact-btn:hover{
	color: #000;
}
.training_desc img{
	vertical-align: inherit;
}

.back_to_all{
	color: #fec117;
  font-size: 16px;
  text-decoration: none;
}
.back_to_all:hover{
	color: #fec117;
  text-decoration: none;
}
.nearest_office_label{
	width: 100%;
	line-height: inherit;
}
.nearest_office_label.english{
	padding-left: 99px;
}
.nearest_office_label.spanish{
	padding-left: 145px;
}

.call-to-action {
	color: #fec117;
}

.call-to-action:hover {
	cursor: pointer;
	text-decoration: underline;
}

.discounted {
	text-decoration: line-through;
}

@media screen and (max-width: 991.98px){
	.contact-head h6{
		margin-left: 0px !important;
	}
	.contact-head p{
		margin-left: 0px !important;
	}
	.contact-btn{
		margin-left: 0px !important;
	}
	.contact_form .contact-placeholder {
		padding: 0 10px !important;
	}
}

.PastCourse_pastCourse__3odRR {
    margin-top: 30px;
}

.PastCourse_pastCourse__3odRR h4 {
    font-size: 16px;
    font-weight: normal;
    min-height:40px;
    margin-bottom: 24px;
}

.PastCourse_borderContent__2Rh-r {
    border-top: 8px solid #ffc116;
    padding-top: 8px;
}

.about-agilar-main .header {
    font-size: 42px;
    font-weight: 700;
}

.about-agilar-main .fp-tableCell {
    vertical-align: top;
}

.group-photo {
    width: 75%;
    margin: 12.5% 12.5%;
    box-shadow: -25px -25px 0px 0px #FFC115;
}

.group-photo-container:before {
    content : "";
    position: absolute;
    left    : 0;
    bottom  : 0;
    height  : 1px;
    width   : 75%;  /* or 100px */
    border-bottom:5px solid #FFC115;
}


.about-agilar-main .container p {
    font-size: .92rem;
    line-height: 28px;
    font-weight: 400;
    color: #000;
}

.agilar-info {
    background: #C0C1C1;
    margin-top: 24px;
}

.agilar-info .container,
.agilar-info .container .row {
    height: 100%;
}

.agilar-info .container .row .info-bit {
    display: flex;
    flex-direction: column;
}

.agilar-info .container .row .info-bit span {
    text-align: center;
    font-size: 16px;
    color: #FFFFFF;
    font-weight: 600;
    text-transform: uppercase;
}


.agilar-info .container .row .info-bit span:first-child {
    font-weight: bold;
    font-size: 42px;
}

.agilar-certifications {
    margin-top: 24px;
}

.about-agilar-main .section.section-1 .header,
.about-agilar-main .section .section-2 .header,
.about-agilar-main .section .section-3 .header,
.about-agilar-main .section.section-4 .history-container ,
.about-agilar-main .section .section-5 .header,
.about-agilar-main .section.section-6 .header {
    margin-top: 140px;
}

.section-6 {
    margin-bottom: 90px;
}

.section-3 .header {
    color: #838484;
}

@media (max-width: 720px) {
    .about-agilar-main .header {
        font-size: 30px;
        font-weight: 700;
    }

    .agilar-info .container .row .info-bit span {
        font-size: 15px;
    }
    .agilar-info .container .row .info-bit span:first-child {
        font-size: 30px;
    }
}

.section-4 .container {
    padding-right: 200px !important;
}

.section-4 .history-container {
    position: relative;
    width: 100%;
    padding: 30px 30px 10px 30px;
    height: 650px;
    background: #FFC115;
}

.section-4 .history-container img {
    position: absolute;
    top: 35px;
    right: -20%;
    width: 50%;
    height: auto;
}

.carousel .slide {
    background: transparent !important;
    text-align: start !important;
}

.history-tab {
    height: 540px;
    width: calc(70% - 30px);
}

.history-tab .header {
    color: #ffffff;
    text-transform: uppercase;
}


.history-container .right-icon,
.history-container .left-icon {
    z-index: 1;
}

.history-container .left-icon {
    position: absolute;
    top: 50%;
    left: 5px;
}

.history-container .right-icon {
    position: absolute;
    top: 50%;
    right: calc(30% + 10px);
}

@media (max-width: 991px) {
    .history-tab .header {
        font-size: 1.2rem;
    }

    .section-4,
    .section-4 .container {
        padding-right: 0 !important;
        padding-left: 0 !important;
    }

    .section-4 .history-container {
        height: calc(100% - 140px);
    }

    .history-container .history-tab {
        width: calc(100% - 30px);
        height: calc(100%);
    }

    .history-container .right-icon {
        right: 5px;
    }
}
.history-progress {
    margin: 20px auto;
    padding: 0 30px;
    text-align: center;
    display: flex;
    width: 100%;
    /* padding-bottom: 80px; */
}
.history-progress .circle,
.history-progress .bar {
    display: inline-block;
    background: #fff;
    width: 10px;
    height: 10px;
    border-radius: 40px;
    border: 1px solid #ffffff;
    vertical-align:top;
}
.history-progress .bar {
    flex-grow: 1;
    position: relative;
    height: 2px;
    margin: 0 0 17px 0;
    border-radius: 0;
    top: 4px;
    vertical-align: top;
    color: #fff;

    background: #fff;
    width: 20px;
    border: 1px solid #ffffff;
}
.history-progress .circle .label {
    display: inline-block;
    width: 32px;
    height: 16px;
    line-height: 16px;
    border-radius: 32px;
    margin-top: 3px;
    color: #b5b5ba;
    font-size: 17px;
}
.history-progress .circle .title {
    color: #ffffff;
    font-size: 13px;
    line-height: 18px;
    text-align: center;
    margin-left: -92px;
    margin-top: 20px;
    width: 200px;
}

.history-progress .circle.active .title {
    color: #454647
}

.history-progress .circle.active {
    background: #454647;
    border-color: #454647;
}

@media (max-width: 991px) {
    .history-progress {
        display: none;
    }
}
.member-container {
    margin: 8px 0;
}

.member-image-container,
.member-container.empty,
.member-container .member-photo {
    height: 125px;
    width: 125px;
}

.member-container .linkedin-icon {
    height: 30px;
    width: 30px;
}

.member-container .job-title {
    font-size: .82rem !important;
    font-weight: 400 !important;
    line-height: 14px !important;
}

.member-image-container {
    position: relative;
}

.team-overlay {
    background: rgba(255, 193, 21, 0.5);
    transition: .5s ease;
    opacity: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    border-radius: 20px;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px 10px;
    height: 125px;
    width: 125px;
    cursor: default;
    text-align: center;
}

.member-container:hover .team-overlay {
    opacity: 1;
}

.member-container:hover .member-photo {
    opacity: 0.3;
}

.member-container .name {
    color: #ffffff;
    font-weight: bold;
    font-size: 18px;
    margin: 0;
}

@media (max-width: 1200px) {
    .member-container .member-photo,
    .team-overlay {
        height: 125px !important;
        width: 125px !important
    }
}

@media (max-width: 991px) {
    .member-container .member-photo,
    .member-image-container,
    .team-overlay {
        height: 100px !important;
        width: 100px !important
    }

    .member-container .name {
        font-size: 14px;
    }

    .member-container .linkedin-icon {
        height: 20px;
        width: 20px;
    }

    .member-container .job-title {
        font-size: 10px !important;
        line-height: 10px !important;
    }

}
.certification-container {
    height: 400px;
    border-radius: 0;
    box-shadow: none;
    transition: border-radius 500ms, box-shadow 500ms;
}
.certification-container img {
    width: 150px;
    margin: 0 auto;
    height: auto;
    position: relative;
    top: 70px;
    transition: top 500ms;
}

.certification-container p {
    visibility: hidden;
    opacity: 0;
    height: 200px;
    font-size: 12px !important;
    line-height: 16px !important;
    text-align: center;
    position: relative;
    top: 100px;
    transition: visibility 0s, top 500ms, opacity 500ms;
}

.certification-container:hover {
    border-radius: 10px;
    box-shadow: 4px -4px 2px -2px rgba(0,0,0,0.29);
}

.certification-container:hover img {
    top: 5px;
}

.certification-container:hover p {
    visibility: visible;
    opacity: 1;
    top: 40px;
}

.mobile-certification-container {
    height: 150px;
    padding: 16px;
}
.mobile-certification-container img {
    max-width: 75%;
    height: auto;
}

.mobile-certification-container p {
    display: none;
    font-size: 12px !important;
    line-height: 16px !important;
    text-align: center;
}

.mobile-certification-container:hover img {
    display: none;
}

.mobile-certification-container:hover p {
    display: block;
}

.managing-partner {
    margin-top: 20px;
}
.managing-partner img {
    width: 330px;
    height: auto;
}

.managing-partner .partner-content {
    background: #DFE0E0;
    width: 330px;
    height: 280px;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
}

.managing-partner .partner-content h4 {
    color: #ffffff;
    font-weight: 600;
    margin: 8px 0 !important;
}

.managing-partner .partner-content p {
    font-size: 0.82em;
    letter-spacing: 0.5px;
    color: #454647;
}

.managing-partner .partner-content a {
    color: #454647;
    text-decoration: none;
    font-weight: bold;
}

.Landing_container__M6621 {
    height: 100%;
    padding-top: 90px;
}

.Landing_title__eBdNa {
    font-weight: 600;
    font-size: 22px;
    margin-bottom: 12px;
}

.Landing_subTitle__1stmS {
    font-weight: 400;
    font-size: 18px;
}

.Landing_logo__1SVgM {
    height: 50px;
}

.Landing_content__1w6mW {
    margin-top: 80px;
    padding: 0 45px;
    display: flex;
    flex-direction: column;
}

.Landing_countriesContainer__31KuT {
    margin-top: 50px;
    flex: 1 1;
    display: flex;
}

.Landing_countriesList__mliLN {
    display: flex;
    flex-direction: column;
    width: 300px;
}

.Landing_mapContainer__C8lvt {
    display: flex;
    flex: 1 1;
    align-items: center;
    justify-content: center;
}

.Landing_mapContainer__C8lvt img {
    width: 100%;
}

.Landing_country__1drGG {
    display: flex;
    align-items: center;
    margin: 20px 0;
}

.Landing_country__1drGG img {
    height: 50px;
    margin-right: 18px;
}

.Landing_country__1drGG .Landing_name__2Y6VD {
    font-size: 18px;
    font-weight: 400;
    margin: 0;
}

.Landing_country__1drGG:hover {
    cursor: pointer;
}

.Landing_country__1drGG:hover .Landing_name__2Y6VD {
    border-bottom: 3px solid #ffc116;
}

@media screen and (max-width: 576px)  {
    .Landing_container__M6621 {
        padding: 30px !important;
    }

    .Landing_content__1w6mW {
        padding: 0 !important;
    }
}


.Newsletter_form__TDEBx {
    margin: 0 auto;
    height: 100%;
    width: 100%;
}

.clients-page .contact-form {
    padding-top: 50px;
}

