.container {
    height: 100%;
    padding-top: 90px;
}

.title {
    font-weight: 600;
    font-size: 22px;
    margin-bottom: 12px;
}

.subTitle {
    font-weight: 400;
    font-size: 18px;
}

.logo {
    height: 50px;
}

.content {
    margin-top: 80px;
    padding: 0 45px;
    display: flex;
    flex-direction: column;
}

.countriesContainer {
    margin-top: 50px;
    flex: 1;
    display: flex;
}

.countriesList {
    display: flex;
    flex-direction: column;
    width: 300px;
}

.mapContainer {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
}

.mapContainer img {
    width: 100%;
}

.country {
    display: flex;
    align-items: center;
    margin: 20px 0;
}

.country img {
    height: 50px;
    margin-right: 18px;
}

.country .name {
    font-size: 18px;
    font-weight: 400;
    margin: 0;
}

.country:hover {
    cursor: pointer;
}

.country:hover .name {
    border-bottom: 3px solid #ffc116;
}

@media screen and (max-width: 576px)  {
    .container {
        padding: 30px !important;
    }

    .content {
        padding: 0 !important;
    }
}

