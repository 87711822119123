.validationError {
    font-size: 80%;
    font-weight: 400;
    padding-left: 0.75rem;
    color: #ff0000;
    position: relative;
    bottom: 31px;
}

.inputContainer {
    height: 60px;
}
