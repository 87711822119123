.page-header {
    font-size: 42px;
    font-weight: 700;
}

.client-image {
    object-fit: contain;
}

.clients .client-image {
    width: 150px;
}

.clients-page .client-image {
    width: 80px;
}

.client-image-container {
    height: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px 0;
}

@media (max-width: 1280px) {
    .client-image-container {
        height: 120px;
    }

    .clients .client-image {
        width: 120px;
    }

    .clients-page .client-image {
        width: 60px;
    }

}

@media (max-width: 720px) {
    .page-header {
        font-size: 30px;
        font-weight: 700;
    }
}
