.pastCourse {
    margin-top: 30px;
}

.pastCourse h4 {
    font-size: 16px;
    font-weight: normal;
    min-height:40px;
    margin-bottom: 24px;
}

.borderContent {
    border-top: 8px solid #ffc116;
    padding-top: 8px;
}
