/*  Responsive CSS */
@media screen and (max-width: 576px) {
	.mobile-footer{
		display: none;
	}
	.mobile-input{
		margin-bottom: 25px;
	}
	.training-row{
		padding-bottom: 0px;
	}
	.training-data{
		padding-bottom: 50px;
	}
	.training-data img{
		margin: 20px auto;
	}
	.yellow-btn{
		width: 100% !important;
	}
	.services-box{
		min-height: 50px;
		margin-bottom: 20px !important;
	}
	.services-data{
		min-height: 150px;
	}
	.courses-box{
		padding-top: 0 !important;
	}
	.course-body{
		flex-direction: column !important;
	}
	.courses-box img{
		margin-top: 15px !important;
		margin-bottom: 15px !important;
	}
	.footer-main ul{
		padding-left: 25px !important;
	}
	.footer-one{
		position: static !important;
	}
	.footer-main-row{
		padding: 0px !important;
	}
	.footer-data{
		margin: 0 auto;
		justify-content: flex-start;
		padding-right: 0px !important;
	}
	.footer-data .footer-item{
		padding: 15px 0px;
	}
	.footer-one .social-box{
		padding: 0px !important;
	}
	.footer-one .social-box .social-content{
		padding: 0px !important;
	}
	.footer-one .social-box p{
		padding: 0px;
	}
	.description-box{
		border-right: 0px !important;
	}
	.description-head{
		flex-direction: column;
	}
	.description-head h4{
		font-size: 16px;
	}
	.description-head img{
		margin-left: 0px !important;
		margin-bottom: 20px;
	}
	.description-content{
		padding: 0px;
	}
	.training-description .registre-head{
		padding-top: 10px;
		padding-left: 0px !important;
	}
	.description-box .registration-content h5{
		padding-left: 0px !important;
		font-size: 15px;
	}
	.description-box .registration-content ul{
		padding: 0px !important;
	}
	.description-box .registration-content ul li{
		padding: 0 10px !important;
		display: block !important;
	}
	.registration-content .form-row .contact-placeholder{
		padding: 0px !important;
	}
	form .register-btn{
		margin-left: 0px !important;
	}
	.description-right{
		padding: 20px 0px;
	}
	.register-label {
		left: 10px !important;
	}
    .cookieConsent .cookies-btn{
    	margin: 15px !important;
    }
    .info-btn{
        height: 60px !important;
        width: 100%!important;
        line-height: 3 !important;
    }
	.more-btn{
        width: 100% !important;
        margin-left: 0px !important;
    }
    .info-head, .more-head{
    	padding: 0px !important;
    }
    .btn-training{
		width: 100% !important;
		padding: 8px 5px !important;
	}
	.gent-item{
		padding-right: 45px !important;
	}
	.footer-one .social-box p{
		padding: 0px !important;
	}
	.form-input{
	  margin-bottom: 0px;
	}
	.form-input input{
		margin-bottom: 10px;
	}
	.validation_error{
		padding-left: 22px;
	}
	.form_valid .validation_error{
		padding-left: 16px;
	}
	.mobile-placeholder{
		top: -9px !important;
	}
	.nearest_office_label.english{
		padding-left: 103px;
	}
	.nearest_office_label.spanish{
		padding-left: 150px;
	}
	.contact-label{
		left: 22px !important;
	}
	.contact-placeholder {
	    padding: 0 10px !important;
	}
	.contact_form .contact-placeholder {
		padding: 0 10px !important;
	}

	.section.clients .container{
		padding-bottom: 32px;
	}

}
@media screen and (max-width: 767px) {
	.slide .intro h1{
		font-size:35px !important;
		line-height: 40px !important;
	}
	.slide .intro .tags{
		font-size:15px !important;
		padding-top:25px !important;
	}
}
@media screen and (max-width: 991.98px){
	.register-label {
		left: 30px !important;
	}

	.contact-label{
		left: 22px !important;
	}
	.validation_error{
		padding-left: 12px;
	}
}
@media screen and (max-width: 1199px){
    .slider-data img{
        height: -webkit-fill-available
    }
    .footer-one h5{
    	font-size: 13px !important;
    }
    .footer-one p{
    	font-size: 11px !important;
    }
    .footer-one .social-box p{
    	font-size: 11px !important;
    }
    .footer-main a{
	    font-size: 11px !important;
	}
}
@media (min-width: 768px) and (max-width: 991.98px){
    .info-head{
        max-width: 100% !important;
        margin-left: 0% !important;
    }
    .info-btn{
    	width: 100% !important;
    }
    .courses-main .courses-container .courses-row{
		margin-left: 0% !important;
		max-width: 100% !important;
	}
	.courses-main .courses-container{
		max-width: 100% !important;
	}
	.btn-training{
		width: 135px !important;
		padding: 8px 5px !important;
	}
	.training-data p{
		min-height: 127px;
	}
	.training-data h4{
		min-height: 50px;
	}
	.btn-register{
		width: 100px;
		padding: 0px;
	}
	.footer-data{
		margin-left: 0 !important;
		max-width: 100% !important;
	}
	/*.footer-one .container{*/
	/*	max-width: 100% !important;*/
	/*	padding: 0px !important;*/
	/*}*/
	.social-box{
		padding: 0px !important;
	}
	.social-content{
		padding: 0px !important;
	}
	.footer-one .social-box p{
		padding: 0px !important;
	}
	.footer-main-row{
		padding: 0px !important;
	}

    .label-top{
    	top: -10px !important;
    }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
	.btn-register{
		width: 100px;
		padding: 0px;
	}
	.trainee-profile p{
		margin-right: 0px;
	}
	.btn-training{
		width: 135px !important;
		padding: 8px 5px !important;
	}
	.more-btn{
        margin-left: 0px !important;
        padding: 10px !important;
    }
    .info-btn{
        padding: 10px 0px !important;
    }
    .info-head{
        max-width: 93% !important;
        margin-left: 6% !important;
    }
}
