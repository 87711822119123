.member-container {
    margin: 8px 0;
}

.member-image-container,
.member-container.empty,
.member-container .member-photo {
    height: 125px;
    width: 125px;
}

.member-container .linkedin-icon {
    height: 30px;
    width: 30px;
}

.member-container .job-title {
    font-size: .82rem !important;
    font-weight: 400 !important;
    line-height: 14px !important;
}

.member-image-container {
    position: relative;
}

.team-overlay {
    background: rgba(255, 193, 21, 0.5);
    transition: .5s ease;
    opacity: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    border-radius: 20px;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px 10px;
    height: 125px;
    width: 125px;
    cursor: default;
    text-align: center;
}

.member-container:hover .team-overlay {
    opacity: 1;
}

.member-container:hover .member-photo {
    opacity: 0.3;
}

.member-container .name {
    color: #ffffff;
    font-weight: bold;
    font-size: 18px;
    margin: 0;
}

@media (max-width: 1200px) {
    .member-container .member-photo,
    .team-overlay {
        height: 125px !important;
        width: 125px !important
    }
}

@media (max-width: 991px) {
    .member-container .member-photo,
    .member-image-container,
    .team-overlay {
        height: 100px !important;
        width: 100px !important
    }

    .member-container .name {
        font-size: 14px;
    }

    .member-container .linkedin-icon {
        height: 20px;
        width: 20px;
    }

    .member-container .job-title {
        font-size: 10px !important;
        line-height: 10px !important;
    }

}