.certification-container {
    height: 400px;
    border-radius: 0;
    box-shadow: none;
    transition: border-radius 500ms, box-shadow 500ms;
}
.certification-container img {
    width: 150px;
    margin: 0 auto;
    height: auto;
    position: relative;
    top: 70px;
    transition: top 500ms;
}

.certification-container p {
    visibility: hidden;
    opacity: 0;
    height: 200px;
    font-size: 12px !important;
    line-height: 16px !important;
    text-align: center;
    position: relative;
    top: 100px;
    transition: visibility 0s, top 500ms, opacity 500ms;
}

.certification-container:hover {
    border-radius: 10px;
    box-shadow: 4px -4px 2px -2px rgba(0,0,0,0.29);
}

.certification-container:hover img {
    top: 5px;
}

.certification-container:hover p {
    visibility: visible;
    opacity: 1;
    top: 40px;
}

.mobile-certification-container {
    height: 150px;
    padding: 16px;
}
.mobile-certification-container img {
    max-width: 75%;
    height: auto;
}

.mobile-certification-container p {
    display: none;
    font-size: 12px !important;
    line-height: 16px !important;
    text-align: center;
}

.mobile-certification-container:hover img {
    display: none;
}

.mobile-certification-container:hover p {
    display: block;
}
