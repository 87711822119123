.about-agilar-main .header {
    font-size: 42px;
    font-weight: 700;
}

.about-agilar-main .fp-tableCell {
    vertical-align: top;
}

.group-photo {
    width: 75%;
    margin: 12.5% 12.5%;
    box-shadow: -25px -25px 0px 0px #FFC115;
}

.group-photo-container:before {
    content : "";
    position: absolute;
    left    : 0;
    bottom  : 0;
    height  : 1px;
    width   : 75%;  /* or 100px */
    border-bottom:5px solid #FFC115;
}


.about-agilar-main .container p {
    font-size: .92rem;
    line-height: 28px;
    font-weight: 400;
    color: #000;
}

.agilar-info {
    background: #C0C1C1;
    margin-top: 24px;
}

.agilar-info .container,
.agilar-info .container .row {
    height: 100%;
}

.agilar-info .container .row .info-bit {
    display: flex;
    flex-direction: column;
}

.agilar-info .container .row .info-bit span {
    text-align: center;
    font-size: 16px;
    color: #FFFFFF;
    font-weight: 600;
    text-transform: uppercase;
}


.agilar-info .container .row .info-bit span:first-child {
    font-weight: bold;
    font-size: 42px;
}

.agilar-certifications {
    margin-top: 24px;
}

.about-agilar-main .section.section-1 .header,
.about-agilar-main .section .section-2 .header,
.about-agilar-main .section .section-3 .header,
.about-agilar-main .section.section-4 .history-container ,
.about-agilar-main .section .section-5 .header,
.about-agilar-main .section.section-6 .header {
    margin-top: 140px;
}

.section-6 {
    margin-bottom: 90px;
}

.section-3 .header {
    color: #838484;
}

@media (max-width: 720px) {
    .about-agilar-main .header {
        font-size: 30px;
        font-weight: 700;
    }

    .agilar-info .container .row .info-bit span {
        font-size: 15px;
    }
    .agilar-info .container .row .info-bit span:first-child {
        font-size: 30px;
    }
}
