.noTraining form {
    margin-top: 24px;
    margin-bottom: 48px;
}

.noTraining {
    margin-top: 32px;
}

.noTraining form.submitted input {
    border-bottom-color: #00B388 !important;
}

.noTraining h4 {
    font-size: 16px;
    font-weight: normal;
    min-height:40px;
    margin-bottom: 24px;
}

.borderContent {
    border-top: 8px solid #ffc116;
    padding-top: 8px;
}
