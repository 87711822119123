.section.about .header .border-head,
.section.clients .header .border-head {
    border-top: 10px solid #ffc115;
    padding-top: 0;
}

.overlay {
    background: rgba(255, 193, 21, 0.7);
    transition: .5s ease;
    opacity: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: .8em;
    padding: 10px;
    height: 100%;
    width: 100%;
    cursor: default;
    text-align: center;
}
.overlay > p:hover {
    cursor: default;
}

.client-image-container:hover .overlay {
    opacity: 1;
}

.client-image-container:hover .client-image {
    /*opacity: 0.3;*/
}

