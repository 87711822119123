
/* upcoming courses */
.courses-main{
	padding-top: 150px;
}
.courses-main h3{
	font-size: 18px;
	font-weight: 600;
	color: #838484;
}
.courses-row{
	padding-bottom: 40px;
}
.courses-box{
	padding-top: 40px;
	padding-bottom: 16px;
	border-bottom: 1px solid #838484;
}
.courses-box img{
	width: 100px;
}
.courses-box .content-head,
.courses-box .content-head a{
	font-size: 19px;
	color: #000;
	font-weight: 600;
	padding-bottom: 15px;
	margin-bottom: 0px;
}
.courses-box:hover .content-head{
	color: #838484;
	text-decoration: none;
}
.courses-box h6{
	font-size: 14px;
	color: #838484;
	font-weight: 400;
	margin-bottom: 0px;
}
.course-body{
	font-size: 14px;
	display: flex;
	flex-direction: row;
	margin-top: 10px;
}
.course-body .course-data{
	padding: 0 5px;
}

.full-warning {
	position: absolute;
	top: -20px;
	width: 100%;
	padding: 0 5px;
	text-align: center;
	color: #FF0000;
	font-size: 13px;
	font-weight: 400;
}
.course-data h6,
.course-data .course-price {
	font-size: 14px;
	font-weight: 400;
	color: #000;
	padding-top: 8px;
}

.course-data .course-price span {
	position: absolute;
	width: 200px;
}

.course-data .course-description{
	font-size: 14px;
	font-weight: 700;
	color: #000;
}
.course-data span:hover{
	cursor: default;
}
.btn-register{
	width: 120px !important;
	font-size: 12px !important;
	height: 35px !important;
	padding: 0px !important;
}

/* Training descritopn */

.training-description{
	padding-top: 120px;
}
.training-description a{
	color: #fec117;
	font-size: 16px;
	text-decoration: none;
}
.training-description a:hover{
	text-decoration: none;
	color: #fec117;
}
.description-head{
	display: flex;
	align-items: center;
	justify-content: space-between;
}
.description-box{
	border-right: 1px solid #ffc116;
}
.description-head h4{
	font-size: 17px;
	font-weight: 700;
	color: #838484;
}
.description-head img{
	width: 90px;
	margin-left: 60px;
}
.description-content{
	padding-right: 30px;
}
.description-content h5{
	font-size: 15px;
	color: #000;
	font-weight: 500;
	margin-bottom: 20px;
}
.description-content h6{
	font-size: 15px;
	color: #000;
	font-weight: 700;
	text-align: justify;
	margin-bottom: 25px;
}
.description-content p{
	font-size: 15px;
	color: #000;
	font-weight: 500;
	margin-bottom: 20px;
}
.description-content ul{
	padding-left: 0px;
}
.description-content ul li{
	list-style-type: none;
	font-size: 15px;
	color: #000;
	font-weight: 500;
}
.description-right h5{
	font-size: 15px;
	color: #000;
	font-weight: 700;
	text-transform: uppercase;
	margin-bottom: 0px;
}
.description-right p{
	font-size: 15px;
	color: #000;
	font-weight: 500;
}
.trainee-profile{
	display: flex;
	align-items: center;
	margin-bottom: 15px;
}
.trainee-profile p{
	font-size: 16px;
	font-weight: 500;
	color: #000;
	margin: 10px;
}
.trainee-profile img:not(.partner-image){
	width: 40px;
	border-radius: 50%;
}
.more-info{
	padding-top: 80px;
}
.more-info h6{
	font-size: 16px;
	font-weight: 500;
	color: #000;
	margin-bottom: 0px;
}
.more-info a{
	font-size: 16px;
	font-weight: 700;
	color: #fec117;
}
.btn-training{
	font-weight: 500 !important;
	width: 100%;
	font-size: 15px !important;
	padding: 8px !important;
}

/* Training Registration */

.training-description .registre-head{
	font-size: 1.12rem;
}
.description-box .registration-content ul li{
	font-size: 16px;
	font-weight: 600;
	color: #000;
	text-transform: uppercase;
}
.description-box .registration-content h5{
	font-size: 16px;
	color: #fec117;
	text-transform: uppercase;
	font-weight: bold;
}
.register-btn{
	width: 150px !important;
	font-weight: 600 !important;
	text-transform: uppercase !important;
}
.more-btn{
	font-weight: 600 !important;
	text-transform: uppercase !important;
	margin-bottom: 24px;
	margin-left: 24px;
	width: auto !important;
}
.info-btn{
	font-weight: 600 !important;
	text-transform: uppercase !important;
	font-size: 14px !important;
	color: #fff !important;
	background-color: #ffc116 !important;
	border-radius: 0px !important;
	padding: 10px !important;
}
.trainee-head{
	display: flex;
    align-items: center;
    justify-content: space-between;
}
.trainee-head h6{
	font-size: 15px;
	font-weight: 500;
	color: #fec117;
	text-transform: uppercase;
	margin-bottom: 0px;
}
.flash_msg{
	color: #fff;
	padding: 8px;
	margin: 0px 15px;
	margin-bottom: 15px;
}
/* courses contact us form */
.contact-form{
	padding-top: 105px;
}
.contact-head h6{
	font-size: 16px;
	font-weight: 700;
	color: #838484;
	/*margin-left: 35px;*/
	margin-bottom: 25px;
	text-transform: uppercase;
}
.contact-head p{
	font-size: 16px;
	font-weight: 500;
	color: #000;
	/*margin-left: 35px;*/
	margin-bottom: 0px;
}
.contact-input{
	border: 0px;
	border-bottom: 3px solid #dcdcdc;
	border-radius: 0px;
	resize: none;
	padding-bottom: 12px;
}
.contact-input:focus{
	box-shadow: none;
	border-bottom: 3px solid #dcdcdc;
}
.contact_form .contact-placeholder {
  padding: 0 48px !important;
}
.contact-placeholder .contact-input:focus + .contact-label {
  display: none;
}
.contact-label {
  position: absolute;
  pointer-events: none;
  top: 0;
  bottom: 0;
  font-size: 14px;
  font-weight: 500;
  left: 60px;
  margin: auto;
  color: #838484;
}
.contact-label span {
  color: #ffc115;
}
.contact-btn{
	width: 140px;
	font-size: 16px;
	font-weight: 500;
	text-transform: uppercase;
	/*margin-left: 48px !important;*/
}
.contact-btn:hover{
	color: #000;
}
.training_desc img{
	vertical-align: inherit;
}

.back_to_all{
	color: #fec117;
  font-size: 16px;
  text-decoration: none;
}
.back_to_all:hover{
	color: #fec117;
  text-decoration: none;
}
.nearest_office_label{
	width: 100%;
	line-height: inherit;
}
.nearest_office_label.english{
	padding-left: 99px;
}
.nearest_office_label.spanish{
	padding-left: 145px;
}

.call-to-action {
	color: #fec117;
}

.call-to-action:hover {
	cursor: pointer;
	text-decoration: underline;
}

.discounted {
	text-decoration: line-through;
}

@media screen and (max-width: 991.98px){
	.contact-head h6{
		margin-left: 0px !important;
	}
	.contact-head p{
		margin-left: 0px !important;
	}
	.contact-btn{
		margin-left: 0px !important;
	}
	.contact_form .contact-placeholder {
		padding: 0 10px !important;
	}
}
