.history-progress {
    margin: 20px auto;
    padding: 0 30px;
    text-align: center;
    display: flex;
    width: 100%;
    /* padding-bottom: 80px; */
}
.history-progress .circle,
.history-progress .bar {
    display: inline-block;
    background: #fff;
    width: 10px;
    height: 10px;
    border-radius: 40px;
    border: 1px solid #ffffff;
    vertical-align:top;
}
.history-progress .bar {
    flex-grow: 1;
    position: relative;
    height: 2px;
    margin: 0 0 17px 0;
    border-radius: 0;
    top: 4px;
    vertical-align: top;
    color: #fff;

    background: #fff;
    width: 20px;
    border: 1px solid #ffffff;
}
.history-progress .circle .label {
    display: inline-block;
    width: 32px;
    height: 16px;
    line-height: 16px;
    border-radius: 32px;
    margin-top: 3px;
    color: #b5b5ba;
    font-size: 17px;
}
.history-progress .circle .title {
    color: #ffffff;
    font-size: 13px;
    line-height: 18px;
    text-align: center;
    margin-left: -92px;
    margin-top: 20px;
    width: 200px;
}

.history-progress .circle.active .title {
    color: #454647
}

.history-progress .circle.active {
    background: #454647;
    border-color: #454647;
}

@media (max-width: 991px) {
    .history-progress {
        display: none;
    }
}