.section.about .fp-tableCell {
    vertical-align: top;
}

.section.about {
    background: linear-gradient(rgba(255, 193, 21, 0.25), rgba(255, 193, 21, 0.25)),
        url('/images/about-us-background.png') 50% 5%;
    background-size: cover;
}

.about .text-row p {
    font-size: .97rem;
    font-weight: 400;
    color: #000;
}

.about .btn.yellow-btn.about-btn {
    margin-top: 32px;
    width: 275px;
}

/*.about .btn.yellow-btn.about-btn:hover {*/
/*    background: #FFFFFF !important;*/
/*    color: #FFC115 !important;*/
/*    border: 2px solid #FFC115;*/
/*}*/
