.managing-partner {
    margin-top: 20px;
}
.managing-partner img {
    width: 330px;
    height: auto;
}

.managing-partner .partner-content {
    background: #DFE0E0;
    width: 330px;
    height: 280px;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
}

.managing-partner .partner-content h4 {
    color: #ffffff;
    font-weight: 600;
    margin: 8px 0 !important;
}

.managing-partner .partner-content p {
    font-size: 0.82em;
    letter-spacing: 0.5px;
    color: #454647;
}

.managing-partner .partner-content a {
    color: #454647;
    text-decoration: none;
    font-weight: bold;
}
